(function ($) {
  Drupal.behaviors.offerBannerFormatter = {
    attach: function (context) {
      var $offerContainer = $('.js-offer-banner-formatter', context);
      var $offerBanner = $('.js-offer-banner-slider', $offerContainer);
      var $offerBannerItem = $('.js-offer-banner-slide__item', $offerBanner);
      var offerBannerSettings = $offerBanner.data() || null;
      var basicSlide = '.js-offer-banner-slide__item';
      var autoplay;
      var autoplaySpeed;
      var showArrows;
      var previousArrowSrc = '';
      var previousArrowAlt = '';
      var nextArrowSrc = '';
      var nextArrowAlt = '';
      var settings = {};
      $('body').addClass('content-block-offer-banner--enabled');

      if (
        offerBannerSettings === null ||
        ($offerBanner.length < 1 && $offerContainer.length < 1) ||
        $offerBannerItem.length < 1
      ) {
        return;
      }
      autoplay = offerBannerSettings.slidesAutoplay || false;
      autoplaySpeed = offerBannerSettings.slidesAutoplaySpeed || 5000;
      showArrows = autoplay === true ? false : true;
      previousArrowSrc = offerBannerSettings.slidesPreviousSrc;
      previousArrowAlt = offerBannerSettings.slidesPreviousAlt;
      nextArrowSrc = offerBannerSettings.slidesNextSrc;
      nextArrowAlt = offerBannerSettings.slidesNextAlt;
      settings = {
        arrows: showArrows,
        slide: basicSlide,
        infinite: true,
        slidesToShow: 1,
        slidesToScroll: 1,
        autoplay: autoplay,
        autoplaySpeed: autoplaySpeed,
        adaptiveHeight: false,
        fade: true,
        prevArrow:
          '<button type="button" class="slick-prev"><img src="' +
          previousArrowSrc +
          '" alt="' +
          previousArrowAlt +
          '"></button>',
        nextArrow:
          '<button type="button" class="slick-next"><img src="' +
          nextArrowSrc +
          '" alt="' +
          nextArrowAlt +
          '"></button>'
      };
      $offerBanner.removeClass('hidden');
      $offerContainer.removeClass('hidden');
      $offerBanner.not('.slick-initialized').slick(settings);
    }
  };
})(jQuery);
